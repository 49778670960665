/* style.css */
header {
	width: 100%;
	height: auto;
	text-align: center;
	z-index: 100;
	transition: all 0.1s ease;
	background-color: rgba(230, 134, 71, 0.951);
	margin: 0; /* Remove default margin */
	padding: 0; /* Remove default padding */
  }
  
  .header-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	height: 100%; /* Make the container take full height of the header */
	margin: 0; /* Remove default margin */
	padding: 0; /* Remove default padding */
  }
  
  header img {
	max-width: 100%;
	max-height: 60%; /* Adjust the max-height as needed */
	width: auto;
	height: auto;
	
	box-sizing: border-box;
	text-transform: uppercase;
	font-weight: 100;
	transition: all 0.1s ease;
	display: flex;
	margin: 0 auto;
  }
  
  header nav {
	width: 100%;
	background-color: #F9E65F;
	display: flex;
	justify-content: center;
	margin: 0; /* Remove default margin */
	padding: 0; /* Remove default padding */
	border-top: 2px solid #e68447; /* Add a border at the top for separation */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  }
  
  header nav a {
	color: #0000FF;
	display: flex;
	padding: 10px 15px;
	text-decoration: none;
	font-weight: bold;
	margin: 0; /* Remove default margin */
	font-size: 16px; /* Adjust font size */
	transition: all 0.3s ease; /* Add a smooth transition effect
	border: 1px solid #e68447; */
	border-radius: 5px;
  }
  header nav p {
	color: #0000FF;
	display: flex;
	padding: 10px 15px;
	text-decoration: none;
	font-weight: bold;
	margin: 0; /* Remove default margin */
	font-size: 16px; /* Adjust font size */
	transition: all 0.3s ease; /* Add a smooth transition effect
	border: 1px solid #e68447; */
	border-radius: 5px;
  }
  
  header nav a:hover {
    background-color: #e68447; /* Change background color on hover */
    color: white; /* Change text color on hover */
    border-radius: 5px; /* Add rounded corners on hover */
}

header nav p:hover {
    background-color: #e68447; /* Change background color on hover */
    color: white; /* Change text color on hover */
    border-radius: 5px; /* Add rounded corners on hover */
}

/* Add this CSS to your stylesheet or within your component's styles */

/* Style for the dropdown container */
.dropdown {
	position: relative;
	display: flex;
	width:auto;
	
  }
  
  /* Style for the "Donations" link */
  .dropdown a {
	color: #0000FF;
	padding: 14px 16px;
	text-decoration: none;
	display: flex;
  }
  
  /* Style for the dropdown content */
  .dropdown-content {
	display: none;
	top: 100%;
	position: absolute;
	background-color: #f9f9f9;
	box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
	z-index: 1;
	border-radius:5px;
	
  }
  
  /* Style for individual dropdown items */
  .dropdown-content a {
	color: #0000FF;
	padding: 12px 16px;
	display: block;
	text-decoration: none;
  }
  
  /* Show the dropdown content when the dropdown is hovered over on desktop */
  .dropdown:hover .dropdown-content {
	display: block;
  }
  
 
	
  
  /* Add styles for responsive design, adjust breakpoints as needed */
  @media screen and (max-width: 768px) {
	header {
	  height: auto;
	  width:auto;
	}
	
	header img {
	  font-size: 36px;
	}
  
	header nav {
	  bottom: 0;
	  background-color: #F9E65F;
	  padding: 5px 0;
	  width:auto;
	}
	
	header nav a {
	  padding: 5px 10px;
	  font-size: 10px;
  		color: #0404f8;
	  border-right: 0.5px solid #e68447;
	  border-left: 0.5px solid #e68447;
	  width:auto;
	}
	header nav a:hover {
        background-color: transparent; /* Remove background color on hover */
        color: #0000FF; /* Reset text color on hover */
        border-radius: 0; /* Remove rounded corners on hover */
    }

	header nav p {
		padding: 5px 10px;
		font-size: 10px;
			color: #0404f8;
		border-right: 0.5px solid #e68447;
		border-left: 0.5px solid #e68447;
		width:auto;
	  }
	  header nav p:hover {
		  background-color: transparent; /* Remove background color on hover */
		  color: #0000FF; /* Reset text color on hover */
		  border-radius: 0; /* Remove rounded corners on hover */
	  }

	/* Hide the dropdown content by default on mobile */
	.dropdown-content {
		display: none;
	  }
	
	  /* Show the dropdown content when the "Donations" link is clicked on mobile */
	  .dropdown.active .dropdown-content {
		display: block;
		
	  }
	
	  /* Adjust padding for better mobile touch experience */
	  .dropdown a {
		padding: 12px 14px;
		color:#0000FF;
	  }
	
	  /* Style for the active state on mobile */
	  .dropdown.active a {
		background-color: #ddd;
	  }
	
	
  }
  
  /* Footer style.css */
/* style.css */
/* Add your existing CSS styles */

.footer-container {
	background-color: #33620D;
	color: white;
	padding: 0 10px; /* Reduce padding for smaller screens */
  }
  
  .left-section, .right-section, .social-section, .copyright-section {
	flex: 1;
	min-width: 200px;
	margin-bottom: 20px;
  }
  
  .left-section {
	text-align: left;
	vertical-align: middle;
  }
  
  .right-section {
	text-align: right;
	vertical-align: middle;
  }

  .social-section, .copyright-section {
	text-align: center; /* Center align content for smaller screens */
  }
  
  .left-section h3, .right-section h3, .social-section h3 {
	font-size: 16px; /* Decrease font size for smaller screens */
	margin-bottom: 10px;
	display: inline-block;
  }
  
  .left-section p, .right-section p {
	font-size: 14px; /* Decrease font size for smaller screens */
	margin: 0;
  }
  .right-section p svg, .left-section p svg {
	vertical-align: middle; /* Align the clock icon with text */
	margin-right: 5px; /* Add some right margin for spacing */
  }
  .right-section p:last-child, .left-section p:last-child  {
	margin-top: 5px; /* Add some top margin to the last paragraph for spacing */
  }
  
  .social-section a {
	color: white;
	font-size: 20px; /* Decrease font size for smaller screens */
	margin: 0 5px; /* Reduce margin for smaller screens */
	vertical-align: middle;
  }
  
  .footer-container .copyright-section {
	width: 100%;
  }
  
  /* Media Query for screens with a maximum width of 768 pixels (typical for tablets and mobiles) */
  @media only screen and (max-width: 768px) {
	.footer-container {
	  padding: 0 5px; /* Further reduce padding for smaller screens */
	}
  
	.left-section, .right-section, .social-section, .copyright-section {
	  min-width: auto; /* Remove minimum width for smaller screens */
	 min-height:auto;
	}
	.left-section {
		text-align: left;
	  }
	  
	  .right-section {
		text-align: right;
	  }

	  .left-section p, .right-section p, .copyright-section p{
		font-size: 10px; /* Decrease font size for smaller screens */
		margin: 0;
	  }

	  .left-section h3, .right-section h3, .social-section h3 {
		font-size: 11px; /* Decrease font size for smaller screens */
		
		display: inline-block;
	  }
	  
  }
  
  
  
  
