.App {
  text-align: center;
}

body {
  margin: 0;
  padding: 0;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.home-container {
  display: flex;
  background-color: #E1DCAD;
  flex-direction: column;
  padding:20px 0 20px 0;
  align-items: center;
  justify-content: center;
  height: auto; /* Set the container height to 100% of the viewport height */
  overflow: hidden;/* Hide any overflowing content */
}

/* App.css or your custom CSS file */


/*.photo-slider {
   Add styling for your photo slider 
}*/
/* Home.css */
.welcomeNoteCard {
  background: radial-gradient(circle, #ecc655, #b7412a);
  border-radius: 10px;
  border: 2px solid #24184e;
  width: calc(83% - 80px);
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: 20px 20px 0 20px;
  /*justify-content: space-between;*/
  align-items: center;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  padding: 20px; /* Add padding to the card grid */
  min-height: calc(100vh - 150px); /* Adjust the max-height to leave space for the header and other elements */
  overflow-y: auto; /* Add vertical scrollbar if content overflows */
}

.card {
  background-color: #f9e65f;
  border-radius: 10px;
  border: 2px solid #24184e;
  width:330px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height:300px;
  /*justify-content: space-between;*/
  align-items: left;
  
}

.card1 {
  background-color: #D0D872;
  border-radius: 10px;
  width:1200px;
  
  padding: 20px;
  
  justify-content: space-between;
  align-items: left;
}

.title {
  background-color: #c93114;
  color: white;
  margin: -20px -20px 0; /* Negative margins to align with card corners and remove space */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: calc(100% + 40px); /* Adjust width to account for negative margins */
  text-align: center;
  padding: 10px 0; /* Add padding only for the inner content */
}



.card-grid1 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  
  margin-top: 20px;
}

.event-card {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 10px;
  height: 250px;
  text-align: center;
}

.event-card table{
  color:#61dafb;
}

.content1 {
  color: #f1f3f6;
  padding: 10px;
  text-align: center;
}
.p2{
  text-align: justify !important;
}
.content {
  color: #00408a;
  padding: 10px;
  text-align: left;
}
.card .note {
  margin-top: auto; /* Adjust the bottom value to your liking */
  font-size:14px;
}
.content ul {
  list-style-type: none;
  padding-left: 0;
}

.content ul li::before {
  content: '\25CB  ';
}

.content ul li a {
  text-decoration: none;
  
}
.custom-carousel {
 
 display: flex;
 /* Center the carousel horizontally */
  padding: 0 20px;/* Add padding to the left and right */
  background-color: #250101;
}
.carousel-image {
  width: 100%; /* Ensure the image takes up the full width of its container */
  height: auto; /* Maintain the aspect ratio of the image */
  max-width: 600px; /* Set a maximum width for larger screens */
}

.interac {
  display: flex;
  align-items: center;
}

.interac img {
  margin-left: 60px;
}

.interac b {
  margin: 0;
}

.cheque {
  display: 'flex';
  justify-content: 'flex-end';
   align-items: 'center';
    margin-right: '60px';
}

.cheque img {
  margin-left: 10px;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top:10px;
}

.gallery-container img {
  width: 100%;
  height: 100%;
  
  border-radius: 8px;
  border: 4px solid #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.gallery-container img:hover {
  transform: scale(1.1);
}

/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  max-width: 80%;
  max-height: 80%;
}

.modal-content img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
button {
  width:100%;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #45a049;
}


@media screen and (max-width: 768px) {
  .card-grid {
    grid-template-columns: 1fr; /* Change to a single column layout */
    padding: 10px; /* Reduce padding for smaller screens */
    min-height: auto; /* Remove max-height to allow content to expand */
  }
  .card {
    width: auto; /* Make the card full width on smaller screens */
    height: auto; /* Allow the height to adjust based on content */
  }
  .card-grid1 {
    grid-template-columns: 1fr; /* Change to a single column layout */
    padding: 10px; /* Reduce padding for smaller screens */
    min-height: auto; /* Remove max-height to allow content to expand */
  }
  .card1 {
    justify-content: space-between;
    align-items: center;
    width: auto;
  }
  
  .interac {
    flex-direction: column;
    align-items: flex-start;
  }

  .interac img {
    margin-left: 0;
    margin-bottom: 10px;
  }

  .cheque {
    flex-direction: column;
    align-items: flex-start;
  }

  .cheque img {
    margin-left: 0;
    margin-bottom: 10px;
  }
  .carousel-image {
    max-width: 100%; /* Allow images to take up the full width on smaller screens */
  }
  .home-container {
    width:auto;
    
  }
  .welcomeNoteCard {
 
    background: radial-gradient(circle, #ecc655, #b7412a);
    border-radius: 10px;
    border: 2px solid #24184e;
    width:330px;
    padding: 20px 10px 40px 10px;
    display: flex;
    flex-direction: column;
    margin: 20px 10px 20px 10px;
    height:300px;
    align-items: center;
  }
  
  .content1 p {
    
    text-align: justify;
    padding-bottom:-20px;
  }
}